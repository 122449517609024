<template>
  <div class="d-flex align-center">
    <user-ref
      :id="id"
      :fullname="value.fullname"
      :email="value.email"
      :hide-email="value.hideEmail"
      hide-phone
      hide-group
    />
  </div>
</template>

<script>
import navigation from '@/navigationController'
// Required import as prop validators are not in the scope
import Column from '@/pages/search/controllers/column'
export default {
  inheritAttrs: false,
  components: {
    UserRef: () => import('@/components/documents-ref/user-ref')
  },
  computed: {
    id () {
      return this.value.documentId?.id
    },
    isAccountContact () {
      return !this.value.documentId || this.value.documentId?.type === 'account-contacts'
    }
  },
  methods: {
    navigateTo () {
      navigation.navigateTo(this.value.documentId.id, this.value.documentId.type)
    }
  },
  props: {
    value: {
      type: Object
    },
    column: Column
  }
}
</script>
<style lang="stylus" scoped>
.align-spacer
  width 32px
</style>
